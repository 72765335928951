import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import ExperienceAccordion from "../../containers/experienceAccordion/ExperienceAccordion.js";
import "./Experience.css";
import { experience } from "../../portfolio.js";
import { Fade } from "react-reveal";
import ExperienceImg from "./ExperienceImg";
import ExperienceCard from "../../components/experienceCard/ExperienceCard.js";

class Experience extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="experience-main">
        <Header theme={theme} />
        <div className="contentroot">
          <div className="basic-experience">
            <Fade bottom duration={2000} distance="40px">
              <div className="experience-heading-div">
                <div className="experience-heading-img-div">
                  {/* <img
									src={require(`../../assests/images/${experience["header_image_path"]}`)}
									alt=""
								/> */}
                  <ExperienceImg theme={theme} />
                </div>
                <div className="experience-heading-text-div">
                  <h1
                    className="experience-heading-text"
                    style={{ color: theme.text }}
                  >
                    {experience.title}
                  </h1>
                  <a
                    href={experience.resumelink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="resume-button">
                      Download full resume
                    </button>
                  </a>
                  <h3
                    className="experience-heading-sub-text"
                    style={{ color: theme.text }}
                  >
                    {experience["subtitle"]}
                  </h3>
                  <p
                    className="experience-header-detail-text subTitle"
                    style={{ color: theme.secondaryText }}
                  ></p>
                  {experience["sections"].map((section) => {
                    if (section.title !== "Work Experience") return null;

                    return section["experiences"].map((exp) => {
                      if (exp["current"])
                        return (
                          <ExperienceCard experience={exp} theme={theme} />
                        );
                      else return null;
                    });
                  })}
                </div>
              </div>
            </Fade>
          </div>
          <ExperienceAccordion
            sections={experience["sections"]}
            theme={theme}
          />
        </div>
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Experience;
