import React from "react";
import "./Footer.css";
import { greeting } from "../../portfolio.js";
/* eslint-disable jsx-a11y/accessible-emoji */

export default function Footer(props) {
  return (
    <>
      <div className="under-footer"></div>
      <div className="footer-div">
        <div className="footer-container">
          <p className="footer-text">
            <span role="img">©</span> 2021-{new Date().getFullYear()}{" "}
            {greeting.owner}
            <br />
            <a
              href="https://ashutoshhathidara.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Original design
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
