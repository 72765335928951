/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Rohit Kalhans' portfolio",
  description:
    "Seasoned engineer and technical leader with a proven track record of building large scale cloud and on-premise products and services. Led critical global initiatives with focus on innovation and excellence. Shaped multiple business strategies and backed it with sustainable solutions catering to billions of users. Vast experience in commercial partnerships, stakeholder management, design and implementation, on-boarding and impact analysis.",
  og: {
    title: "Rohit Kalhans' Portfolio",
    type: "website",
    url: "http://rohit.kalhans.org/",
  },
};

//Home Page
const greeting = {
  title: "Rohit Kalhans",
  logo_name: "org.kalhans.rohit",
  owner: "kalhans.org",
  nickname: "rkalhans",
  subTitle:
    "Seasoned engineer and technical leader with a proven track record of building large scale cloud and on-premise products and services. Led critical global initiatives with focus on innovation and excellence. Shaped multiple business strategies and backed it with sustainable solutions catering to billions of users. Vast experience in commercial partnerships, stakeholder management, design and implementation, on-boarding and impact analysis. ",
  resumeLink:
    "https://www.dropbox.com/s/ti40sq8fxhrmz9y/Rohit%20Kalhans%20Resume.pdf?dl=0",
  portfolio_repository: "https://github.com/rkalhans",
  githubProfile: "https://github.com/rkalhans",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/rkalhans",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/rkalhans/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  // {
  //   name: "YouTube",
  //   link: "https://www.youtube.com/channel/UC_amoXmmxSY9KusoDczDTXQ",
  //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: "Gmail",
    link: "mailto:rohit.kalhans@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Twitter",
    link: "https://twitter.com/wiseanotherday",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/rkalhans",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/rohitkalhans/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Digital Advertising Products",
      fileName: "DataScienceImg",
      skills: [
        " Building and supporting sustainable digital advertisements business on global scale",
        " More than 5 years of experience in the domain - Built mobile app install ads business from scratch.",
        " Highly skilled in impact analysis, data driven developement and product market fit",
        " Loves to build digital ads product flywheel for self-sustainable customer acquisition through promoted, sponsored and organic growth",
      ],
      softwareSkills: [
        {
          skillName: "Meta",
          fontAwesomeClassname: "simple-icons:meta",
          style: {
            backgroundColor: "transparent",
            color: "#0467DF",
          },
        },
        {
          skillName: "Search Ads",
          fontAwesomeClassname: "simple-icons:appstore",
          style: {
            backgroundColor: "transparent",
            color: "#0D96F6",
          },
        },
        {
          skillName: "News Ads ",
          fontAwesomeClassname: "simple-icons:applenews",
          style: {
            backgroundColor: "transparent",
            color: "#FD415E",
          },
        },
        {
          skillName: "Google Ads ",
          fontAwesomeClassname: "simple-icons:googleads",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    {
      title: "Machine learning and AI",
      fileName: "AIImg",
      skills: [
        "Specization in deep neural network and Machine learning using tensorflow and keras ",
      ],
      softwareSkills: [
        {
          skillName: "Tensor Flow",
          fontAwesomeClassname: "simple-icons:tensorflow",
          style: {
            backgroundColor: "transparent",
            color: "#FF6F00",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "transparent",
            color: "#D00000",
          },
        },
        {
          skillName: "Pandas",
          fontAwesomeClassname: "simple-icons:pandas",
          style: {
            backgroundColor: "transparent",
            color: "#150458",
          },
        },
      ],
    },
    {
      title: "Search and relevance",
      fileName: "FullStackImg",
      skills: [
        "Building search products with Elasticsearch and Lucene",
        "Expericened in deploying and maintining 400+ node Elasticsearch cluster",
        "Proficient in search and relevance algoriths and using implicit feedback from users to optimize the results ranking",
      ],
      softwareSkills: [
        {
          skillName: "ElasticSearch",
          fontAwesomeClassname: "simple-icons:elastic",
          style: {
            color: "#00bfb3",
          },
        },
        {
          skillName: "LogStash",
          fontAwesomeClassname: "simple-icons:logstash",
          style: {
            color: "#fed10a",
          },
        },
        {
          skillName: "Kibana",
          fontAwesomeClassname: "simple-icons:kibana",
          style: {
            color: "#f04e98",
          },
        },
        {
          skillName: "Azure Devops",
          fontAwesomeClassname: "simple-icons:azuredevops",
          style: {
            color: "##0078D7",
          },
        },
        {
          skillName: "Microsoft Azure ",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0078D4",
          },
        },
      ],
    },
    {
      title: "Cloud Compute, Map reduce and Data Storage",
      fileName: "CloudInfraImg",
      skills: [
        " Certified System architect for AWS and hands-on experience of building very large scale systems with AWS compute, S3, MKS, EMR and EKS",
        " Built and deployed very large scale systems on Azure IAAS ans PASS",
        " Created first ever multi-threaded data replication system for MySQL",
        " Working knowledge of creating and deploying large Kubernetes clusers with terraform",
        " Creating, optimizing and deploying large scale pipelines for Apache Spark, Hive, Kafka, Cassandra",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Apache Hive",
          fontAwesomeClassname: "simple-icons:apachehive",
          style: {
            color: "#FDEE21",
            backgroundColor: "#0c8ab6",
          },
        },
        {
          skillName: "Apache Kafka",
          fontAwesomeClassname: "simple-icons:apachekafka",
          style: {
            color: "#C71A36",
          },
        },
        {
          skillName: "Apache Spark",
          fontAwesomeClassname: "simple-icons:apachespark",
          style: {
            color: "#E25A1C",
          },
        },

        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "Miscellaneous",
      fileName: "DesignImg",
      skills: [
        "Highly technical and hands-on in Programming, System Design and software testing  ",
        "Can program fluently in C, C++. Java, Python, Scala, Hacklang, C# and multiple other high level languages.",
      ],
      softwareSkills: [
        {
          skillName: "C",
          fontAwesomeClassname: "simple-icons:c",
          style: {
            color: "#A8B9CC",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#00599C",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/ashutosh_1919",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@ashutosh391",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Birla Institute of Technology and Science  Goa, India ",
      subtitle: "M.Sc. (Tech.) Information Systems",
      logo_path: "bitsp-logo.png",
      alt_name: "Bits Pilani",
      duration: "2007 - 2011",
      descriptions: [],
      website_link: "https://www.bits-pilani.ac.in/goa/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Neural Networks and Deep Learning",
      subtitle: "Andrew Ng",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/7393W2TVCHPM",
      alt_name: "deeplearning.ai",
      color_code: "#fcef32",
    },
    {
      title: "Architecting with AWS",
      subtitle: " Amazon",
      logo_path: "aws.png",
      certificate_link:
        "https://www.dropbox.com/s/8ohucevrvh2xll7/architectingwithaws.png?dl=0",
      alt_name: "arcitecting with aws",
      color_code: "#0C9D5899",
    },
    {
      title: "Security engineering with AWS",
      subtitle: " Amazon",
      logo_path: "aws.png",
      certificate_link:
        "https://www.dropbox.com/s/p2d8bcsb1oum8fo/security.png?dl=0",
      alt_name: "deeplearning.ai",
      color_code: "#8ebcf5",
    },
  ],
};

// Experience Page
const experience = {
  title: "Work Experience",
  subtitle: "",
  description: "",
  resumelink:
    "https://www.dropbox.com/s/ti40sq8fxhrmz9y/Rohit%20Kalhans%20Resume.pdf?dl=0",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Lead Software Engineer",
          company: "Meta Inc.",
          company_url: "https://meta.com/",
          logo_path: "meta-logo.png",
          duration: "Aug 2021-  present",
          location: "London, UK",
          description:
            "Lead software engineer working on Digital Advertisements for Mobile App Installs. Also leading the engineering partnerships with two major OEMs amounting to annual revenue of ~$14M in Alpha stage.",
          pointers: [
            {
              text:
                "Tech. Lead for ads data platform built for Global Expansion which aimed at launching search and display ads product in 37 countries",
              sub: [
                {
                  text:
                    "Platform served ~800 batch jobs and ~30 real-time pipelines running DNN model-training, ETL (OLAP) pipelines and Data warehousing workloads.",
                },
                {
                  text:
                    "End to end ownership of the platform, including design, architecture, deployments and maintenance.",
                },
                {
                  text:
                    "Managed team of 8 developers, 3 QEs, 3 Vendors (contract SMEs) for building the platform.",
                },
              ],
            },
            {
              text:
                "Coordinated with Program Managers, Engineering managers, Product managers and the leadership team to createyearly roadmaps and delivery timelines.",
            },
            {
              text:
                "Pursued strategic partnerships with teams within organization and with third parties including Confluent and Cloudera.",
            },
            {
              text:
                "Instrumental in setting up team in India development center from scratch, including being POC for hiring drives and provided long-term mentorship for other engineers in the team.",
            },
          ],
          color: "#0879bf",
          current: true,
        },
        {
          title: "Senior Software Engineer",
          company: "Apple Inc.",
          company_url: "https://apple.com/",
          logo_path: "apple-logo.png",
          duration: "July 2017- July 2022  ",
          location: "Hyderabad, India",
          description:
            "Led multiple initiatives for $3B+ online advertising business of Apple focussed on data ingestion, processing, reporting and AI/ML requirements of the business.",
          color: "#0879bf",
        },
        {
          title: "Software Engineer",
          company: "Microsoft Corp.",
          company_url: "https://microsoft.com/",
          logo_path: "ms-logo.png",
          duration: "May-2015-July 2017",
          location: "Hyderabad, India",
          description:
            "At Microsoft, I worked in the Azure devOps team which is the core offering of Microsoft for developers, where I built many highly scalable cloud services and products.",
          color: "#0879bf",
        },
        {
          title: "Software Engineer",
          company: "Flipkart Internet pvt. ltd.",
          company_url: "https://flipkart.com/",
          logo_path: "flipkart-logo.png",
          duration: "March-2014-April 2015",
          location: "Bangalore, India",
          description:
            "At Flipkart (a leading e-retailer and online marketplace in India), I was the product owner and the lead engineer of the data ingestion system for the Flipkart data platform.",
          color: "#0879bf",
        },
        {
          title: "Software Engineer",
          company: "Oracle Corp.",
          company_url: "https://oracle.com/",
          logo_path: "oracle-logo.png",
          duration: "July 2011-Feb 2014",
          location: "Bangalore, India",
          description:
            "I started my career with Sun Microsystem (later Oracle India Pvt. Ltd.), where I worked on MySQL replication implementing some key optimizations like Multi threaded replication slave nodes.",
          color: "#0879bf",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzb=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "animated_ashutosh.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI, React, Android, Cloud and Opensource Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://ashutoshhathidara.wordpress.com",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Ambavadi vas, Kanodar, T.A.-Palanpur, Dist.-Banaskantha, Gujarat - 385520",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/MpMqtRZytFchMkZ76",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+91 8320758513",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
