import React, { Component } from "react";
import "./ExperienceCard.css";
import md5 from "md5";

class ExperienceCard extends Component {
  getCurrent = (data) => {
    if (!data) return null;
    return data.map((cNode) => (
      <ul key={`node_${md5(cNode.text)}`}>
        <li>{cNode.text}</li>
        {cNode.sub && this.getCurrent(cNode.sub)}
      </ul>
    ));
  };

  render() {
    const experience = this.props.experience;
    const theme = this.props.theme;
    return (
      <div className="experience-card">
        <div className="experience-card-logo-div">
          <img
            className="experience-card-logo"
            src={require(`../../assests/images/${experience["logo_path"]}`)}
            alt=""
          />
        </div>
        <div className="experience-card-body-div">
          <div className="experience-card-header-div">
            <div className="experience-card-heading-left">
              <h3
                className="experience-card-title"
                style={{ color: theme.text }}
              >
                {experience["title"]}
              </h3>
              <p
                className="experience-card-company"
                style={{ color: theme.text }}
              >
                <a
                  href={experience["company_url"]}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: theme.text }}
                >
                  {experience["company"]}
                </a>
              </p>
            </div>
            <div className="experience-card-heading-right">
              <p
                className="experience-card-duration"
                style={{ color: theme.text }}
              >
                {experience["duration"]}
              </p>
              <p
                className="experience-card-location"
                style={{ color: theme.text }}
              >
                {experience["location"]}
              </p>
            </div>
          </div>
          <p
            className="experience-card-description"
            style={{ color: theme.text }}
          >
            {experience["description"]}
          </p>
          <p className="experience-card-pointers" style={{ color: theme.text }}>
            {this.getCurrent(experience["pointers"])}
          </p>
        </div>
      </div>
    );
  }
}

export default ExperienceCard;
